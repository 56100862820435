<template>
  <div>
    <b-button
      variant="primary"
      class="mb-1"
      @click="$router.push({name: 'acordos'})"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span>Voltar</span>
    </b-button>
    <div>
      <b-card>
        <b-row>
          <b-col>
            <b-row>
              <h4 class="ml-1">
                Acordo: {{ acordo.id }}
              </h4>
              <b-table
                small
                stacked
                borderless
                :fields="fieldsUser"
                :items="itemsUser"
              />
            </b-row>
          </b-col>
          <b-col>
            <b-table
              stacked
              borderless
              small
              :fields="fieldsAcordo"
              :items="itemsAcordo"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div>
      <b-card
        title="Parcelas"
      >
        <b-table
          responsive
          outlined
          bordered
          :fields="fieldsParcelas"
          :items="itemsParcelas"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BTable,
  BRow,
  BCol,
} from 'bootstrap-vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
  },
  data() {
    return {
      parcelas: {
        id: '',
      },
      acordo: {
        id: '',
        user: '',
      },
      fieldsUser: [
        'usuario',
        'cliente',
        { key: 'created_at', label: 'Data Criação' },
        'vencimento',
        'total',
      ],
      itemsUser: [],
      fieldsAcordo: [
      ],
      itemsAcordo: [],
      fieldsParcelas: [
        'parcela',
        'boleto',
        { key: 'data_vencimento', label: 'vencimento' },
        'valor',
      ],
      itemsParcelas: [],
    }
  },
  async beforeCreate() {
    await axios.get(`api/v1/parcelas/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.acordo.id = res.data.dados.parcela.acordos.id
      this.acordo.user = res.data.dados.parcela.usuario_empresas.user.name
      // eslint-disable-next-line array-callback-return
      this.itemsUser.push({
        usuario: res.data.dados.parcela.usuario_empresas.user.name,
        cliente: res.data.dados.parcela.acordos.cliente.nome,
        created_at: this.dataHora(res.data.dados.parcela.created_at),
        total: res.data.dados.parcela.acordos.valor_atualizado,
      })
      this.itemsAcordo.push({
        juros: res.data.dados.parcela.acordos.juros,
        multa: res.data.dados.parcela.valor_multa,
        honorario: res.data.dados.parcela.valor_honorario,
        pagamento: res.data.dados.parcela.forma_pagamento,
      })
      this.itemsParcelas.push({
        parcela: res.data.dados.parcela.numero_parcela,
        data_vencimento: this.formatTimezone(res.data.dados.parcela.data_vencimento),
        valor: this.valorBr(parseFloat(res.data.dados.parcela.acordos.valor_parcela), true),
      })
    })
  },

}
</script>
